import React, { useState, useEffect, createElement } from 'react'
import Quiz from '../components/Quiz'
import Container from '@mui/material/Container';
import { useNavigate, useLocation } from 'react-router-dom'
import { getRequest } from '../util/APIUtils';
import PropTypes from 'prop-types';
import {Grid, Tabs, Tab, Box, Typography} from '@mui/material';
import DOMPurify from 'dompurify';
import PatternTab from './PatternTab';


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const QuizPage = (props) =>  {
  const [quizData, setQuizes] = useState(null);
  const location = useLocation()
  const { exam_id, pattern, syllabus } = location.state
  const [value, setValue] = useState(0);

  useEffect(() => {
    getRequest(`/public/exam/${exam_id}`)
      .then(result => {
        setQuizes(result)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      })
  }, [])


  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  return (
    <Container sx={{ py: 5 }}>
    <Box justifyContent="flex-end">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="exam options">
          <Tab label="Mock Tests" {...a11yProps(0)} />
          <Tab label="Pattern" {...a11yProps(1)} />
          <Tab label="Practice" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Grid container spacing={4}>
          {quizData &&
            quizData.map((item) => (
              <Quiz key={item.id} quiz={item} authenticated={props.authenticated}/>
            ))}
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {/* <Typography gutterBottom variant="body" 
              component="div" align="left" 
              dangerouslySetInnerHTML = {{__html: DOMPurify.sanitize(pattern)}}/> */}
              <PatternTab examName={pattern}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <div>{syllabus}</div>
      </CustomTabPanel>
    </Box>
    </Container>   
  )
}

export default QuizPage