import * as React from 'react'
import {  Container, Card, CardContent, Typography} from '@mui/material';

function TermsConditions() {
  return (
    <Container sx={{ py: 1 }}>
        <Card align="left">
            <CardContent>
            <Typography gutterBottom variant="h4" component="div">
            Terms and Conditions
            </Typography>            
              <Typography gutterBottom variant="body" component="div">
            Welcome to PrepFury! These Terms and Conditions ("Terms") govern your access to and use of the website located at https://prepfury.com (the "Website") and any related services provided by PrepFury ("we," "us," or "our"). By accessing or using the Website, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you must not use the Website.
            </Typography>
            <Typography gutterBottom variant="h5" component="div">
            1. Use of the Website
            </Typography>            
            <Typography gutterBottom variant="body" component="div">
            <b>1.1 Eligibility:</b> To use the Website, you must be at least 13 years old. By using the Website, you represent and warrant that you are at least 13 years of age and have the legal capacity to enter into these Terms.
            <br/>
            <b>1.2 License:</b> We grant you a limited, non-exclusive, non-transferable, and revocable license to access and use the Website for personal, non-commercial purposes, in accordance with these Terms.
            <br/>
            <b>1.3 User Accounts:</b> To access certain features of the Website, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information, including your password, and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.
            </Typography>
            <Typography gutterBottom variant="h5" component="div">
            2. User Conduct
            </Typography>            
            <Typography gutterBottom variant="body" component="div">
            By using the Website, you agree not to:
            <ul>
            <li>Violate any applicable laws or regulations</li>
            <li>Infringe on the intellectual property rights of others</li>
            <li>Transmit any harmful or malicious software</li>
            <li>Engage in any activity that disrupts or interferes with the Website's operation</li>
            <li>Post or share any content that is unlawful, harmful, defamatory, or otherwise objectionable</li>
            <li>Use the Website for any commercial purposes without our prior written consent</li>
            </ul>
            </Typography>
            <Typography gutterBottom variant="h5" component="div">
            3. Content
            </Typography>            
            <Typography gutterBottom variant="body" component="div">

            <b>3.1 User-Generated Content:</b> You may have the ability to post, upload, or otherwise submit content to the Website ("User Content"). By submitting User Content, you grant us a non-exclusive, royalty-free, worldwide, perpetual, and irrevocable license to use, reproduce, distribute, and display your User Content in connection with the Website.
            <br/>
            <b>3.2 Intellectual Property:</b> All content on the Website, including text, graphics, logos, images, and software, is the property of PrepFury or its licensors and is protected by copyright, trademark, and other intellectual property laws. You may not use, reproduce, or distribute any content from the Website without our prior written permission.
            </Typography>
            <Typography gutterBottom variant="h5" component="div">
            4. Payments and Refunds
            </Typography>            
            <Typography gutterBottom variant="body" component="div">

            <b>4.1 Payment Terms:</b> If you purchase any products or services through the Website, you agree to provide accurate payment information and authorize us to charge the applicable fees to your payment method.
            <br/>
            <b>4.2 Refund Policy:</b>  Refer our <a href="/refunds">Refund Policy</a>
            </Typography>
            <Typography gutterBottom variant="h5" component="div">
            5. Disclaimers and Limitation of Liability
            </Typography>            
            <Typography gutterBottom variant="body" component="div">
            <b>5.1 Disclaimers:</b> The Website and all content, products, and services available through it are provided "as is" and "as available" without any warranties of any kind, either express or implied. We do not warrant that the Website will be uninterrupted, error-free, or secure.
            <br/>
            <b>5.2 Limitation of Liability:</b> To the fullest extent permitted by law, PrepFury shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with your use of the Website or any products or services obtained through it.
            </Typography>
            <Typography gutterBottom variant="h5" component="div">
            6. Indemnification
            </Typography>            
            <Typography gutterBottom variant="body" component="div">

            You agree to indemnify and hold harmless PrepFury, its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including legal fees, arising out of or in any way connected with your use of the Website or your violation of these Terms.
            </Typography>            
            <Typography gutterBottom variant="h5" component="div">
            7. Termination
            </Typography>            
            <Typography gutterBottom variant="body" component="div">

            We reserve the right to terminate or suspend your access to the Website at our sole discretion, without notice, for conduct that we believe violates these Terms or is harmful to other users, us, or third parties, or for any other reason.
            </Typography>            
            <Typography gutterBottom variant="h5" component="div">
            8. Governing Law
            </Typography>            
            <Typography gutterBottom variant="body" component="div">

            These Terms are governed by and construed in accordance with the laws of India, without regard to its conflict of law principles. You agree to submit to the exclusive jurisdiction of the courts located in India for the resolution of any disputes arising out of or relating to these Terms or your use of the Website.
            </Typography>
            <Typography gutterBottom variant="h5" component="div">
            9. Changes to These Terms
            </Typography>            
            <Typography gutterBottom variant="body" component="div">
            We may update these Terms from time to time. Any changes will be posted on this page with an updated effective date. Your continued use of the Website after the posting of changes constitutes your acceptance of the revised Terms.
            </Typography>            
            <Typography gutterBottom variant="h5" component="div">
            10. Contact Us
            </Typography>            
            <Typography gutterBottom variant="body" component="div">
            If you have any questions or concerns about these Terms, please contact us at:
            <a href='mailto:cloudmedley@gmail.com'>PrepFury Email</a>
            </Typography>            
               </CardContent>
          </Card>
          </Container>
  );
}
export default TermsConditions;