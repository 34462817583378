import React from 'react';
import {
    Navigate,
    Outlet
  } from "react-router-dom";
  
  
const PrivateRoute = ({ component: Component, authenticated, ...rest }) => (
    // <Route
    //   {...rest}
    //   render={props =>
    //     authenticated ? (
    //       <Component {...rest} {...props} />
    //     ) : (
    //       <Navigate
    //         to={{
    //           pathname: '/login',
    //           state: { from: props.location }
    //         }}
    //       />
    //     )
    //   }
    // />
    authenticated? <Outlet />:<Navigate to={{
                pathname: '/login'
                // ,
                // state: { from: props.location }
              }} />
);
  
export default PrivateRoute