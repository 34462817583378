import React from 'react'
import { Grid, Container, Card, CardContent, Typography, Button} from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';


function Thankyou() {
  const {state} = useLocation();

  const { exam} = state; // Read values passed on state

  return (
    <Container sx={{ py: 1 }}>
    <Grid container spacing={1}>
      <Grid item xs={8}>
        <Card align="left" style={{ border: "none", boxShadow: "none" }}>           
            <CardContent>
              <Typography gutterBottom variant="h4" component="div">
               Congratulations on choosing <b>{exam.title}</b> mock test series from PrepFury !!!
              </Typography>
              <Typography gutterBottom variant="h5" component="div">
                Happy Learning.
              </Typography>
              <Button component={Link} to="/" variant="contained" color="primary" style={{margin:5}}>
                Take Another Quiz
              </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
  )
}

export default Thankyou