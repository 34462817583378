export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
//'https://api.prepfury.com';
//export const API_BASE_URL = 'http://localhost:8080';
export const SITE_URL = process.env.REACT_APP_SITE_URL
//'https://prepfury.com';
//export const SITE_URL = 'http://localhost:3000';
export const ACCESS_TOKEN = 'accessToken';

export const OAUTH2_REDIRECT_URI = SITE_URL+ '/oauth2/redirect'

export const GOOGLE_AUTH_URL = API_BASE_URL + '/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const FACEBOOK_AUTH_URL = API_BASE_URL + '/oauth2/authorize/facebook?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const GITHUB_AUTH_URL = API_BASE_URL + '/oauth2/authorize/github?redirect_uri=' + OAUTH2_REDIRECT_URI;
