import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app/App';
import registerServiceWorker from './registerServiceWorker';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter as Router } from 'react-router-dom';

const theme = createTheme({
  palette: {
    primary:{
      main: '#1976d2'
    },
    secondary: {
      main: '#E33E7F'
    }
  },
  typography: { 
    fontFamily: 'Garamond (serif)', 
    fontSize: 16, 
    fontWeight: 'bold', 
}, 
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
    <ThemeProvider theme={theme}>
    <App />
    </ThemeProvider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
