import React from 'react';
import { Container, Typography, List, ListItem, ListItemText, Box } from '@mui/material';
import IeltsPattern from './patterns/IeltsPattern';
import SscPattern from './patterns/SscPattern';


const PatternTab = ({examName}) => {
    if(examName == 'IELTS'){
        return <IeltsPattern/>
    }else if(examName == 'SSCCGL'){
        return <SscPattern/>
    }
            

};

export default PatternTab;
