import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Card, CardMedia, CardContent, Rating, Grid, Typography, CardActionArea, Slide, Paper } from '@mui/material';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import Exam from '../components/Exam.js'
import examData from '../data/exam.json'
import "./Home.css"


const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ff6d75',
  },
  '& .MuiRating-iconHover': {
    color: '#ff3d47',
  },
});

function Home({allowedExams, authenticated}) {
  return (
    <main>
            <Container sx={{ py: 5 }}>
              <Grid item xs={12}>
                <Card class="blink">                  
                    <CardContent sx={{display:'flex', justifyContent:'center', fontSize: '20px'}}>            
                    Use code &nbsp;<b>FREE100</b>&nbsp; at checkout to get FREE access to the tests every month.
                    </CardContent>
                </Card>                
              </Grid>
              <Grid container spacing={5}>
                {examData &&
                  examData.map((item) => {
                      let hasAccess = false
                      if(allowedExams){
                        hasAccess = allowedExams.split(",").includes(String(item.id))
                      }
                    return <Exam key={item.id} exam={item} hasAccess={hasAccess} authenticated={authenticated}/>
                  }
                )}
                
              </Grid>
            </Container>
          </main>
  );
}

export default Home;
