import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import PrepFuryLogo from '../img/prepfury-logo.png';
import { Link } from 'react-router-dom';
import { deepOrange } from '@mui/material/colors';

const pageLinks = {
  'My Tests': 'my-tests',
 // 'Progress': 'progress',
  //'Blog': 'blog'
};

function stringAvatar(name) {

  return {
    sx: {
      bgcolor: deepOrange[500],
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}


function AppHeader(props) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <a href="/"><img src={PrepFuryLogo} /></a>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            PrepFury
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {Object.keys(pageLinks).map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center" textTransform="none">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            PrepFury
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {Object.keys(pageLinks).map((page) => (
              <Button
                key={page}
                component={Link}
                to={`/${pageLinks[page]}`}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block', textTransform: 'none'}}
              >
                {page}
              </Button>
            ))}
          </Box>
          {
            props.authenticated ?
              (
                <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                  <Tooltip title="Profile">
                    <IconButton component={Link} to="/profile">
                      <Avatar {...stringAvatar(props.currentUser.name)} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Logout">
                    <Button
                      key='logout'
                      onClick={props.onLogout}
                      sx={{ my: 2, color: 'white', display: 'block'}}
                    >
                      Logout
                    </Button>
                  </Tooltip>
                </Box>
              ) : (
                <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                  <Button
                    key='signin'
                    component={Link} to="/login"
                    sx={{ my: 2, color: 'white', display: 'block' }}
                  >
                    Sign In
                  </Button>
                  <Button
                    key='signup'
                    component={Link} to="/signup"
                    sx={{ my: 2, color: 'white', display: 'block' }}
                  >
                    Sign Up
                  </Button>
                </Box>
              )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default AppHeader;