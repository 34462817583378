import React, { useState } from 'react';
import './Signup.css';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { GOOGLE_AUTH_URL, FACEBOOK_AUTH_URL, GITHUB_AUTH_URL } from '../../constants';
import { signup } from '../../util/APIUtils';
import fbLogo from '../../img/fb-logo.png';
import googleLogo from '../../img/google-logo.png';
import githubLogo from '../../img/github-logo.png';

const Signup = (props) => {
    if (props.authenticated) {
        return <Navigate to={{ pathname: "/", state: { from: props.location } }} />;
    }

    return (
        <div className="signup-container">
            <div className="signup-content">
                <h1 className="signup-title">Signup with PrepFury</h1>
                <SocialSignup />
                <div className="or-separator">
                    <span className="or-text">OR</span>
                </div>
                <SignupForm {...props} />
                <span className="login-link">Already have an account? <Link to="/login">Login!</Link></span>
            </div>
        </div>
    );
}

const SocialSignup = () => {
    return (
        <div className="social-signup">
            <a className="btn btn-block social-btn google" href={GOOGLE_AUTH_URL}>
                <img src={googleLogo} alt="Google" /> Sign up with Google</a>
            {/* <a className="btn btn-block social-btn facebook" href={FACEBOOK_AUTH_URL}>
                <img src={fbLogo} alt="Facebook" /> Sign up with Facebook</a>
            <a className="btn btn-block social-btn github" href={GITHUB_AUTH_URL}>
                <img src={githubLogo} alt="Github" /> Sign up with Github</a> */}
        </div>
    );
}

const SignupForm = (props) => {
    const [state, setState] = useState({ name: '', email: '', password: '', showSuccessPopup: false });
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        setState(prevState => ({
            ...prevState,
            [inputName]: inputValue
        }));
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const signUpRequest = { name: state.name, email: state.email, password: state.password };

        signup(signUpRequest)
            .then(response => {
                setState(prevState => ({ ...prevState, showSuccessPopup: true }));
                setTimeout(() => {
                    navigate("/login");
                }, 3000); // Redirect after 3 seconds
            }).catch(error => {
                // Handle error
            });
    }

    return (
        <div>
            {state.showSuccessPopup && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setState({ ...state, showSuccessPopup: false })}>&times;</span>
                        <h2>Success!</h2>
                        <p>You're successfully registered.</p>
                        <p>Redirecting to login...</p>
                    </div>
                </div>
            )}
            <form onSubmit={handleSubmit}>
                <div className="form-item">
                    <input type="text" name="name"
                        className="form-control" placeholder="Name"
                        value={state.name} onChange={handleInputChange} required />
                </div>
                <div className="form-item">
                    <input type="email" name="email"
                        className="form-control" placeholder="Email"
                        value={state.email} onChange={handleInputChange} required />
                </div>
                <div className="form-item">
                    <input type="password" name="password"
                        className="form-control" placeholder="Password"
                        value={state.password} onChange={handleInputChange} required />
                </div>
                <div className="form-item">
                    <button type="submit" className="btn btn-block btn-primary">Sign Up</button>
                </div>
            </form>
        </div>
    );
}

export default Signup;