import * as React from 'react';
import { Box, Container, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';


function AppFooter() {
  return (
    <Paper sx={{marginTop: 'calc(10% + 60px)',
      width: '100%',
      position: 'fixed',
      bottom: 0,
      width: '100%'
      }} component="footer" square variant="outlined">
        <Container maxWidth="lg">
          
  
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "space-between",
              display: "flex",
              mb: 2,
            }}
          >
            <Typography variant="caption" color="initial">
              <span>&nbsp;</span>
            </Typography>
            <Typography variant="caption" color="initial">
              Copyright ©2024. CLOUDMEDLEY LLP.
            </Typography>
            <Typography variant="caption">
              <Link to="/privacy-policy">Privacy Policy</Link>&nbsp;|&nbsp;
              <Link to="/refunds">Refund Policy</Link>&nbsp;|&nbsp;
              <Link to="/terms">Terms & Conditions</Link>       
            </Typography>
          </Box>
        </Container>
      </Paper>
  );
}
export default AppFooter;