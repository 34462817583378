import React, { useState} from 'react'

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CardMedia,Chip, Grid, Container, Card, CardContent, Typography, Button, RadioGroup, Radio, Stack, Checkbox} from '@mui/material';
import {FormControlLabel, FormControl,InputAdornment,Input, OutlinedInput, TextField, FormHelperText} from '@mui/material';
import { postRequest } from '../util/APIUtils';

function Checkout() {
    const {state} = useLocation();
    const [showPromoField, setShowPromoField] = useState(true)
    const [promoCode, setPromoCode] = useState("FREE100")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [discount, setDiscount] = useState(0)
    const [showDiscount, setShowDiscount] = useState(false)

    const [phoneError, setPhoneError] = useState(false)
    const [termsError, setTermsError] = useState(false)

    const [phoneErrorMessage, setPhoneErrorMessage] = useState("")
    const [termsErrorMessage, setTermsErrorMessage] = useState("")
    const [isChecked, setIsChecked] = useState(false)

    
    



    const termsLabel = "I have read and agree to the website terms and conditions."
    const navigate = useNavigate();
    const { exam} = state; // Read values passed on state
    const placeOrder = e => {
        console.log("phone place order",phoneNumber)
        console.log("promo  place order",promoCode)
        if(phoneNumber == '')
        {
            setPhoneError(true)
            setPhoneErrorMessage("Phone Number is Mandatory.")
            return
        }
        if(isChecked == false)
        {
            setTermsError(true)
            setTermsErrorMessage("Please read and accept terms & conditions.")
            return
        }
        console.log("Posted data to phone pe", {"examId":exam.id, "mrp":exam.price, "discount":discount, "promoCode":promoCode, "mobileNumber":phoneNumber})
        postRequest('/api/payment/phonepe', {"examId":exam.id, "mrp":exam.mrp, "price":exam.price,"validUntil":exam.validUntil, "discount":discount, "promoCode":promoCode, "mobileNumber":phoneNumber, "validUntil": exam.valid})
        .then(response  => {
            if(JSON.stringify(response) === '{}'){
              navigate('/thankyou', {state:{exam: exam}})
            }else{
              window.open(response, "_self")
            }
          }
      );
        //console.log("Response received from post request:", response)
        //navigate('/thankyou', {state:{exam: exam}})
    }
    const handlePaymentMethod = e => {
        console.log('on change called', e.target.value)
        if(e.target.value == 'promo')
        {
            setShowPromoField(true)
        }
        else if (e.target.value == 'phonepe'){
          setDiscount(0)
          setShowDiscount(false)
          setShowPromoField(false)
        }
        else{
            setDiscount(0)
            setShowDiscount(false)
            setShowPromoField(false)
        }

      }
    const applyPromo = () =>{
        if(promoCode == 'DIWALI' || promoCode=='FREE100'){
            setDiscount(exam.price)
            setShowDiscount(true)
        }
        else{
            setDiscount(0)
            setShowDiscount(false)
        }
    }

    const handlePhone  = (e) =>{
        setPhoneNumber(e.target.value)
        setPhoneError(false)
        setPhoneErrorMessage("")
    }
    const handlePromo  = e =>{
        setPromoCode(e.target.value)
    }
    const handleTerms = () => {
        if(!isChecked){
            setTermsError(false)
            setTermsErrorMessage("")
        }
        setIsChecked(!isChecked)
    }
  
  return (
    <Container sx={{ py: 1 }}>
    <Grid container spacing={1}>
      <Grid item xs={8}>
        <Card align="left" style={{ border: "none", boxShadow: "none" }}>           
            <CardContent>
            <Typography gutterBottom variant="h4" component="div">
            Congratulations on choosing <b>{exam.title}</b> mock test series from PrepFury !!!
              </Typography>
                <Typography>&nbsp;</Typography>
              <Stack direction="row">
              <Typography gutterBottom variant="h5" component="div">
                Price :
              </Typography>
              <Typography gutterBottom variant="h5" component="div">
                <b>&emsp;&emsp;&emsp;&emsp;&emsp;&#x20b9;{exam.price}</b>
              </Typography>              
              </Stack>
              

              {showDiscount && 
              <><Stack direction="row"><Typography gutterBottom variant="body" component="div">
                Discount :
              </Typography>
              <Typography gutterBottom variant="body" component="div">
                &emsp;&#x20b9;{discount}
              </Typography></Stack>
              <Stack direction="row"><Typography gutterBottom variant="h5" component="div">
              Final Price :
            </Typography>
            <Typography gutterBottom variant="h5" component="div">
              <b>&emsp;&#x20b9;{exam.price - discount}</b>
            </Typography></Stack></>}          
            
              <Typography>&nbsp;</Typography>
              <Stack direction="row" alignItems={'baseline'}>
              <Typography gutterBottom variant="h5" component="div">
                Mobile :
              </Typography>
              <Typography>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</Typography>
              <OutlinedInput id="outlined-adornment-phone" error={phoneError} size='small'
                    required startAdornment={<InputAdornment position="start">+91</InputAdornment>}
                    inputProps={{maxLength:10}} onChange={handlePhone}/>
                    <FormHelperText error id="phone-error">{phoneErrorMessage}</FormHelperText>              
              </Stack>
              <Typography>&nbsp;</Typography>

              <Stack direction="row" alignItems={'baseline'}>
              <Typography gutterBottom variant="h5" component="div">
              Payment Mode:
              </Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="promo" name="radio-buttons-group"  onChange={handlePaymentMethod}>
                {/* <FormControlLabel value="phonepe" control={<Radio />} label="UPI/Debit Card/Credit Card/NetBanking" /> */}
                <FormControlLabel value="promo" control={<Radio size='small'/>} label="Promo Code" />
                {/* <FormControlLabel value="dbt" control={<Radio size='small'/>} label="Direct Bank Transfer" /> */}
                <FormControlLabel value="phonepe" control={<Radio size='small'/>} label="PhonePe" />
            </RadioGroup>
            {showPromoField && <><Input placeholder="Enter Promo Code" defaultValue="FREE100" onChange={handlePromo} size='small' variant='standard'/>&nbsp;<Button variant="outlined" size="small" sx={{textTransform: 'none'}} onClick={applyPromo} >Apply</Button></>}
            </Stack>
            
            <FormControlLabel error={termsError} required control={<Checkbox size="small" checked={isChecked}/>} label={<span>I have read and agree to the website <Link target="_blank" to={'/terms'}>terms and conditions</Link>.</span>} onChange={handleTerms} />
            <FormHelperText error id="terms-error">{termsErrorMessage}</FormHelperText>              

            <div/>
            <Button variant="contained" sx={{textTransform:'none'}} onClick={() => placeOrder()} >
            Place Order
            </Button>
              </CardContent>
              </Card>
          </Grid>
          
          <Grid item xs={4}>
          <CardMedia
                component="img"
                image={exam.url}
                alt={exam.title}
            />
          </Grid>

          </Grid>
          </Container>
  )
}

export default Checkout