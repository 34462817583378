import * as React from 'react'
import { Container, Card, CardContent, Typography} from '@mui/material';

function RefundPolicy() {
  return (
    <Container sx={{ py: 1 }}>
        <Card align="left">
            <CardContent>
            <Typography gutterBottom variant="h4" component="div">
            Refund Policy
              </Typography>            
              <Typography gutterBottom variant="body" component="div">
              At Prepfury, we strive to provide high-quality services and ensure customer satisfaction. Please note that all sales are final, and we do not offer refunds for any services or products once purchased.
If you have any concerns or issues regarding your purchase, feel free to reach out to us via email at <a href='mailto:cloudmedley@gmail.com'>PrepFury Email</a>
. We are here to assist you and address any concerns you may have.
Thank you for your understanding and for choosing Prepfury.
            </Typography>            
               </CardContent>
          </Card>
          </Container>
  );
}
export default RefundPolicy;